import {
  getOptionsPayment,
  getOptionsPreValidatePurchase,
  getOptionsPreValidatePurchaseXTR,
  getOptionsValidatePurchase,
  getOptionsValidatePurchaseXTR,
} from "@/constants/endpoints/payment";
import { _auth, _axios } from "../axios";
import { IPaymentProps } from "./interfaces";

export const getPaymentData = async ({
  titlePayment,
  description,
  payload,
  currency,
  prices,
}: IPaymentProps): Promise<string> => {
  try {
    const options = getOptionsPayment({
      titlePayment,
      description,
      payload,
      currency,
      prices,
    });

    const response = await _axios(options);

    const data = await response.data;

    return data.result as string;
  } catch (error) {
    console.error("Error payment :", error);
    throw error;
  }
};

export const validatePurchase = async (
  address: string,
  price: string,
  shop_item_id: string,
  booster_type: string
) => {
  try {
    const options = getOptionsValidatePurchase({
      address,
      price,
      shop_item_id,
      booster_type,
    });

    const response = await _auth(options);

    const result = await response.data;

    return result as { transaction_id: string; success: boolean };
  } catch (error) {
    console.error("Error payment :", error);
    throw error;
  }
};

export const preValidatePurchase = async (
  address: string,
  price: string,
  shop_item_id: string,
  booster_type: string
) => {
  try {
    const options = getOptionsPreValidatePurchase({
      address,
      price,
      shop_item_id,
      booster_type,
    });

    const response = await _auth(options);

    const result = await response.data;

    return result as boolean;
  } catch (error) {
    console.error("Error payment :", error);
    throw error;
  }
};

export const validateXTRPurchase = async (
  user_id: string,
  shop_item_id: string,
  booster_type: string,
  price: string
) => {
  try {
    const options = getOptionsValidatePurchaseXTR({
      user_id,
      shop_item_id,
      booster_type,
      price,
    });

    const response = await _auth(options);

    const result = await response.data;

    return result as {transaction_id: string; success: boolean};
  } catch (error) {
    console.error("Error payment :", error);
    throw error;
  }
};

export const preValidateXTRPurchase = async (
  user_id: string,
  shop_item_id: string,
  booster_type: string,
  price: string
) => {
  try {
    const options = getOptionsPreValidatePurchaseXTR({
      user_id,
      shop_item_id,
      booster_type,
      price,
    });

    const response = await _auth(options);

    const result = await response.data;

    return result as boolean;
  } catch (error) {
    console.error("Error payment :", error);
    throw error;
  }
};
