import { TOKEN } from "@/constants/cookies";
import { getCookie } from "@/utils/cookies";
import axios from "axios";

const API_URL = process.env.API_URL

const _auth = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

_auth.interceptors.request.use(
  (config) => {
    const token = getCookie(TOKEN);
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

_auth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const _axios = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

export { _auth, _axios };
