import { getPaymentData } from "@/api/payment";
import { PAYMENT } from "@/components/Cards/CardShop/cardShop.constants";
import { EVENTS_PURCHASE } from "@/interfaces/interfaces";
import { getTranslation } from "@/utils/location";
import WebApp from "@twa-dev/sdk";

export const useInvoice = () => {
  const createInvoice = async (
    webApp: typeof WebApp,
    title: string,
    price: number
  ): Promise<string> => {
    if (!webApp) {
      throw new Error("WebApp is not available");
    }

    const { title_payment, description, payload, currency } = PAYMENT;
    const prices = [
      {
        label:
          title.length > 0 ? title : getTranslation("Специальное предложение"),
        amount: price,
      },
    ];

    const titlePayment =
      (title_payment + " " + title).length > 2
        ? title_payment + " " + title
        : getTranslation("Специальное предложение");

    const invoiceUrl = await getPaymentData({
      titlePayment,
      description,
      payload,
      currency,
      prices,
    });

    return new Promise((resolve, reject) => {
      webApp.openInvoice(invoiceUrl, () => {
        webApp.onEvent("invoiceClosed", (response: any) => {
          const { status } = response;
          if (status == EVENTS_PURCHASE.PAID) {
            resolve("Оплата прошла");
          } else {
            reject(new Error("Оплата не прошла"));
          }
        });
      });
    });
  };

  return { createInvoice };
};
