import React, { useMemo } from "react";
import { getInitials } from "@/utils/formatters/getInitials";
import { ICardInitialsProps } from "./interface";

import styles from "./styles.module.scss";

const CardInitials = ({ username, className }: ICardInitialsProps) => {
  const text = useMemo(() => getInitials(username), [username]);
  
  return (
    <div className={className ? className : styles.card}>
      <span className={styles.card__initials}>{text}</span>
    </div>
  );
};

export default React.memo(CardInitials);
