import React from "react";
import Image from "next/image";
import { IFooterCard } from "./interfaces";
import Link from "next/link";
import { getTranslation } from "@/utils/location";

import styles from "./styles.module.scss";

const FooterCard = ({
  src,
  alt,
  name,
  href,
  isActive,
  onClick,
}: IFooterCard) => {
  const handleClick = () => {
    onClick(name);
  };

  return (
    <li
      className={`${styles.footer__nav__item} ${isActive ? styles.active : ""}`}
    >
      <Link href={href}>
        <Image
          onClick={handleClick}
          src={src}
          alt={alt}
          loading="lazy"
          width={64}
          height={64}
        />
        {isActive && (
          <span className={styles.item_name}>{getTranslation(name)}</span>
        )}
      </Link>
    </li>
  );
};

export default React.memo(FooterCard);
