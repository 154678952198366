import { useState, useCallback } from "react";
import { toast, Id, TypeOptions } from "react-toastify";
import { useDispatch } from "react-redux";
import { setIsValidate } from "@/store/shop/shopSlice";

const useToastManager = () => {
  const [activeToasts, setActiveToasts] = useState<Id[]>([]);
  const dispatch = useDispatch();

  const notifyPending = useCallback((text:string,type?:TypeOptions): Id => {
    dispatch(setIsValidate(true));

    activeToasts.forEach((toastId) => toast.dismiss(toastId));
    setActiveToasts([]);

    const toastId = toast.loading(text, {
      type: type || "default",
      autoClose: 120000,
      closeOnClick: false,
      draggable: false,
    });

    setActiveToasts((prev) => [...prev, toastId]);

    setTimeout(() => {
      if (toast.isActive(toastId)) {
        toast.dismiss(toastId);
        setActiveToasts((prev) => prev.filter((id) => id !== toastId));
      }
    }, 125000);

    return toastId;
  }, [activeToasts, dispatch]);

  const updateToast = useCallback(
    (toastId: Id, message: string,type?:TypeOptions) => {
      if (toastId) {
        dispatch(setIsValidate(false));
        try {
          toast.update(toastId, {
            render: message,
            type: type || "success",
            autoClose: 1500,
            isLoading: false,
          });

          setTimeout(() => {
            if (toast.isActive(toastId)) {
              toast.dismiss(toastId);
              setActiveToasts((prev) => prev.filter((id) => id !== toastId));
            }
          }, 2000);
        } catch (error) {
          console.error("Ошибка при обновлении тоста:", error);
        }
      }
    },
    [dispatch]
  );

  const closePending = useCallback(
    (toastId: Id, message: string) => {
      if (toastId) {
        dispatch(setIsValidate(false));
        try {
          toast.update(toastId, {
            render: message,
            type: "error",
            isLoading: false,
            autoClose: 1500,
          });

          setTimeout(() => {
            if (toast.isActive(toastId)) {
              toast.dismiss(toastId);
              setActiveToasts((prev) => prev.filter((id) => id !== toastId));
            }
          }, 2000);
        } catch (error) {
          console.error("Ошибка при закрытии тоста:", error);
        }
      }
    },
    [dispatch]
  );

  return {
    notifyPending,
    updateToast,
    closePending,
  };
};

export default useToastManager;
