import Image from "next/image";
import React, { useMemo } from "react";
import { RootState } from "@/store";
import { formatTime } from "@/utils/formatters/formatTime";
import { useSelector } from "react-redux";
import { BoostDisplayProps } from "./interfaces";
import boost from "@/assets/icons/boost.png";
import booster from "@/assets/icons/buster.png";
import BoosterTimes from "@/components/BoosterTimes";

import styles from "./styles.module.scss";

const BoostDisplay: React.FC<BoostDisplayProps> = ({}) => {
  const { multipliers_boosters } = useSelector(
    (state: RootState) => state.user.balance
  );

  const remainingTime = multipliers_boosters.time_remaing;

  const multiplier = multipliers_boosters.booster;
  const time = useMemo(
    () => formatTime(multipliers_boosters.time_remaing),
    [multipliers_boosters.time_remaing]
  );
  return (
    <>
      <BoosterTimes />
      <Image
        className={styles.coin__value__boost}
        width={18}
        src={boost}
        alt="booster"
        loading="lazy"
      />
      <span className={styles.coin__value__boost__multiplier}>
        {multiplier}{" "}
      </span>
      <Image
        className={styles.coin__value__boost__image}
        width={18}
        src={booster}
        alt="booster"
        loading="lazy"
      />
      <span
        className={`${styles.coin__value__boost__time} ${
          remainingTime > 3600 ? styles.coin__value__boost__time__long : ""
        }`}
      >
        {remainingTime > 0 && time}
      </span>
    </>
  );
};

export default React.memo(BoostDisplay);
