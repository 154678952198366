import React, { ReactNode } from "react";
import { createPortal } from "react-dom";

import styles from "./styles.module.scss";

export interface PortalModalProps {
  isOpen: boolean;
  children: ReactNode;
}

const PortalModal = ({ isOpen, children }: PortalModalProps) => {
  const refModals = document.getElementById("modals");
  
  if (!refModals || !isOpen) return;

  return createPortal(
    <div className={styles.modal__wrapper}>{children}</div>,
    refModals
  );
};

export default PortalModal;
