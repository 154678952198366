type BoosterTimesPropsType = {};

import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import coin from "@/assets/icons/coin.png";
import { RootState } from "@/store";
import Image from "next/image";

const BoosterTimes = ({}: BoosterTimesPropsType) => {
  const count = useSelector(
    (state: RootState) => state.user.balance.multipliers_boosters.count
  );

  return (
    <div className={styles.wrapper}>
      {Array.from({ length: count }).map((_, index) => (
        <Image src={coin} width={10} height={10} alt="coin" key={index} />
      ))}
    </div>
  );
};

export default BoosterTimes;
