import {
  IPaymentProps,
  IValidatePuchase,
  IValidatePuchaseXTR,
} from "@/api/payment/interfaces";
import { AxiosRequestConfig } from "axios";

export const getOptionsPayment = ({
  titlePayment,
  description,
  payload,
  currency,
  prices,
}: IPaymentProps): AxiosRequestConfig => ({
  url: `https://api.telegram.org/bot${process.env.TELEGRAM_BOT_TOKEN}/createInvoiceLink`,
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  data: {
    title: titlePayment,
    description: description,
    payload: payload,
    provider_token: "",
    currency: currency,
    prices: prices,
  },
});

export const getOptionsValidatePurchase = ({
  address,
  price,
  shop_item_id,
  booster_type,
}: IValidatePuchase): AxiosRequestConfig => ({
  url: `purchase/validate-transaction`,
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 120000,
  params: {
    address: address,
    price: price,
    shop_item_id,
    booster_type,
  },
});

export const getOptionsPreValidatePurchase = ({
  address,
  price,
  shop_item_id,
  booster_type,
}: IValidatePuchase): AxiosRequestConfig => ({
  url: `purchase/pre-validate-transaction`,
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000,
  params: {
    address: address,
    price: price,
    shop_item_id,
    booster_type,
  },
});

export const getOptionsValidatePurchaseXTR = ({
  user_id,
  shop_item_id,
  booster_type,
  price,
}: IValidatePuchaseXTR): AxiosRequestConfig => ({
  url: `purchase/validate-stars-transaction`,
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 120000,
  params: {
    user_id: user_id,
    shop_item_id,
    booster_type,
    price,
  },
});

export const getOptionsPreValidatePurchaseXTR = ({
  user_id,
  shop_item_id,
  booster_type,
  price,
}: IValidatePuchaseXTR): AxiosRequestConfig => ({
  url: `purchase/pre-validate-stars-transaction`,
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 120000,
  params: {
    user_id: user_id,
    shop_item_id,
    booster_type,
    price,
  },
});
