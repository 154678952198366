import React, { useCallback } from "react";
import { useRouter } from "next/router";
import Avatar from "@/components/Avatar";
import HudButtons from "@/components/HudButtons";
import CoinDisplay from "@/components/CoinDisplay";
import useClickSound from "@/hooks/useClickSound";
import { PROFILE_LINK } from "./header.constants";


import styles from "./styles.module.scss";
import ActionButton from "../ActionButton";
import { sendWsEvent } from "@/utils/ws";

const Header = () => {
  const router = useRouter();
  const playSound = useClickSound();

  const handleClickProfile = useCallback(() => {
    playSound();
    router.push(PROFILE_LINK);
  }, [playSound, router]);


  return (
    <header className={styles.header}>
      <div onClick={handleClickProfile}>
        <Avatar
          className={styles.avatar__wrapper}
          classNameHat={styles.hat}
          classNameImage={styles.avatar__image}
        />
      </div>
      <div className={styles.coins__wrapper}>
        <CoinDisplay />
        
      </div>
    </header>
  );
};

export default React.memo(Header);
